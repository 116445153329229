<template>
    <div class="card dveb-white-card">
        <div>
            <b-dropdown variant="outline-light" text="En Son Eklenenler" class=" mb-4">
                <b-dropdown-item>Müşteri Ekle</b-dropdown-item>
                <b-dropdown-item>Kiracı Ekle</b-dropdown-item>
                <b-dropdown-item>Mülk Ekle</b-dropdown-item>
                <b-dropdown-item>Talep Ekle</b-dropdown-item>
            </b-dropdown>   

        </div>
        <div class="row">
            <div class="col-md col-6 mt-8">
                <div class="card dveb-gray-card">
                    <div class="gray-card-body">
                        <img src="/image/image-147.png" class="img-fluid" alt="">
                        <div class="gray-card-text">
                            <h4>Kartal Mai Rezidans</h4>
                            <div class="gray-card-badges-area">
                                <div class="gray-card-badges">
                                    <p>Bünyamin Ceylan</p>
                                    <b-badge variant="info">Primary</b-badge>
                                </div>
                                <div class="gray-card-badges">
                                    <p>Bünyamin Ceylan</p>
                                    <b-badge variant="primary">Primary</b-badge>
                                </div>
                                <div class="gray-card-price">
                                    <p><span class="price-span">20.000₺</span> /aylık</p>
                                    <span>01.07.2023</span>
                                </div>
                            </div>
                            <div class="row ">
                                <b-button variant="primary" class="mx-auto mt-2">Detayları Gör</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md col-6 mt-8">
                <div class="card dveb-gray-card">
                    <div class="gray-card-body">
                        <img src="/image/image-147.png" class="img-fluid" alt="">
                        <div class="gray-card-text">
                            <h4>Kartal Mai Rezidans</h4>
                            <div class="gray-card-badges-area">
                                <div class="gray-card-badges">
                                    <p>Bünyamin Ceylan</p>
                                    <b-badge variant="info">Primary</b-badge>
                                </div>
                                <div class="gray-card-badges">
                                    <p>Bünyamin Ceylan</p>
                                    <b-badge variant="primary">Primary</b-badge>
                                </div>
                                <div class="gray-card-price">
                                    <p><span class="price-span">20.000₺</span> /aylık</p>
                                    <span>01.07.2023</span>
                                </div>
                            </div>
                            <div class="row ">
                                <b-button variant="primary" class="mx-auto mt-2">Detayları Gör</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md col-6 mt-8">
                <div class="card dveb-gray-card">
                    <div class="gray-card-body">
                        <img src="/image/image-147.png" class="img-fluid" alt="">
                        <div class="gray-card-text">
                            <h4>Kartal Mai Rezidans</h4>
                            <div class="gray-card-badges-area">
                                <div class="gray-card-badges">
                                    <p>Bünyamin Ceylan</p>
                                    <b-badge variant="info">Primary</b-badge>
                                </div>
                                <div class="gray-card-badges">
                                    <p>Bünyamin Ceylan</p>
                                    <b-badge variant="primary">Primary</b-badge>
                                </div>
                                <div class="gray-card-price">
                                    <p><span class="price-span">20.000₺</span> /aylık</p>
                                    <span>01.07.2023</span>
                                </div>
                            </div>
                            <div class="row ">
                                <b-button variant="primary" class="mx-auto mt-2">Detayları Gör</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md col-6 mt-8">
                <div class="card dveb-gray-card">
                    <div class="gray-card-body">
                        <img src="/image/image-147.png" class="img-fluid" alt="">
                        <div class="gray-card-text">
                            <h4>Kartal Mai Rezidans</h4>
                            <div class="gray-card-badges-area">
                                <div class="gray-card-badges">
                                    <p>Bünyamin Ceylan</p>
                                    <b-badge variant="info">Primary</b-badge>
                                </div>
                                <div class="gray-card-badges">
                                    <p>Bünyamin Ceylan</p>
                                    <b-badge variant="primary">Primary</b-badge>
                                </div>
                                <div class="gray-card-price">
                                    <p><span class="price-span">20.000₺</span> /aylık</p>
                                    <span>01.07.2023</span>
                                </div>
                            </div>
                            <div class="row ">
                                <b-button variant="primary" class="mx-auto mt-2">Detayları Gör</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md col-6 mt-8">
                <div class="card dveb-gray-card">
                    <div class="gray-card-body">
                        <img src="/image/image-147.png" class="img-fluid" alt="">
                        <div class="gray-card-text">
                            <h4>Kartal Mai Rezidans</h4>
                            <div class="gray-card-badges-area">
                                <div class="gray-card-badges">
                                    <p>Bünyamin Ceylan</p>
                                    <b-badge variant="info">Primary</b-badge>
                                </div>
                                <div class="gray-card-badges">
                                    <p>Bünyamin Ceylan</p>
                                    <b-badge variant="primary">Primary</b-badge>
                                </div>
                                <div class="gray-card-price">
                                    <p><span class="price-span">20.000₺</span> /aylık</p>
                                    <span>01.07.2023</span>
                                </div>
                            </div>
                            <div class="row ">
                                <b-button variant="primary" class="mx-auto mt-2">Detayları Gör</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'last-cards',
};

</script>
<style lang="scss" >
body{
    font-size: 16px;
}
.transparent-button{
    button{
        background-color: #FFF !important;
    }
}
.gray-card-text {
    margin-top: .75rem;
    h4{
            font-size: 1.2rem ;
        }
    .gray-card-badges-area {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
        border-bottom: 1px solid rgba(16, 38, 64, .25);
       
        .gray-card-badges {
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
                font-size: .75rem;
                opacity: .5;
                font-weight: 500;
            }
        }
        .gray-card-price{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            font-size: 0.625rem;
            font-weight: 300;
                
                .price-span{
                    color: rgba(73, 155, 252, 1) !important;
                    font-size: 1.5rem !important;
                    line-height: 1.3rem;
                    opacity: 1;
                    font-weight: 600;
                }
            
            
        }
    }
}</style>
